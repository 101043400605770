.photo {
	.caption {
		text-align: left;
		@media (min-width: $medium) {
			position: absolute;
		    bottom: -15px;
		    left: -15px;			
		}
	    padding: 20px;		
	}	
}

.page-index .photo {
	position: relative;
	&.cd-fixed-background {
	    @media (min-width: $medium) {
	        height: calc(100vh - 80px);
	        background-repeat: no-repeat;
	        background-size: cover;
	        background-position: center center;
	        background-attachment: fixed;        
	    }
	    @media (max-width: $medium - 1) {
	    	background: none!important;
	    }
	}	

}

.page-permalink {
	.post.photo:not(.photoset) {
		display: inline-block;
	}
	.post__photo {
		display: inline-block;  
		position: relative;
		img {
			display: block;
		}
		.caption {
			text-align: left;
		}		
	}

}