/****************************************
** Header
*****************************************/

.header {    
    @media (min-width: $large) {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1;
    }

    &.animate-in {
        opacity: 1;
    }

    &__left {
        display: inline-block;
    }

    &__logo_avatar {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        z-index: 10;
        position: relative;
    }

    &__logo_image {
        vertical-align: middle;
        display: inline-block;
        max-width: 100%;
        margin-right: 25px;
    }    

    &__title {
        display: inline-block;
    }

    ul li,
    .search input {
        display: block;
        padding: 5px 0;
        border: 0;
        width: 100%;
        &:first-child {
            padding: 10px 0 5px;
        }
    }
    &__description {
        font-size: 18px;
        font-family: inherit;
    }
    .header__nav a {
        font-size: 18px;
    }
    .navigation {
        position: absolute;
        display: block;
        z-index: 1;
        li a {
            font-family: inherit;
            font-weight: 400;
        }
    }
    .search input {
        &[type="text"] {
            &:focus {
                outline: none;
                border-color: inherit;
            }
        }
        &[type="submit"] {
            display: none;
        }
    }
}