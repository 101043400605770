@charset "UTF-8";
/****************************************
** Reset
*****************************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/****************************************
** Basics
*****************************************/
* {
  box-sizing: border-box; }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-size: 62.5%; }

body {
  background: white;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4444444444;
  /*  The meta tag we wrote earlier for Google Web Fonts allows you to dynamically add the font name anywhere in the document. Using the text variable above allows all of this to work dynamically. */ }

h1 {
  font-weight: bold; }

ol, ul {
  list-style: none; }

a {
  color: #81D0FF;
  text-decoration: none;
  transition: all .3s ease-in-out; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Basic image/video/media handling */
video, object, embed {
  height: auto !important; }

video, object, embed {
  max-width: 100%; }

.notes {
  display: none; }

.wrapper {
  padding: 0 10px; }

.container {
  padding: 10px 0; }

@media (min-width: 1024px) {
  .wrapper {
    padding: 0 25px; }
  .container {
    padding: 25px 0; } }

.overlay.hide {
  opacity: 0;
  display: none; }

.overlay {
  transition: opacity 0.2s;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background: white; }
  .overlay .overlay__container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

#-hide {
  display: none; }

iframe[id^=like_iframe] {
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  top: 50%;
  left: 0; }

.more_notes_link {
  float: left; }

.bottom {
  float: right;
  padding: 15px;
  text-align: right; }
  @media (min-width: 1024px) {
    .bottom {
      position: fixed;
      bottom: 25px;
      padding: 0;
      right: 25px; } }

/****************************************
** Typography
*****************************************/
h1, .h1 {
  font-size: 24px; }

h2, .h2 {
  font-size: 28px; }

h3, .h3 {
  font-size: 24px; }

h4, .h4 {
  font-size: 18px; }

h5, .h5 {
  font-size: 14px; }

h6, .h6 {
  font-size: 12px; }

h1, h2, h3, h4, h5, h6 {
  font-family: inherit; }

input {
  font-size: 16px; }

.m-scene {
  overflow-x: hidden; }
  .m-scene .scene_element {
    animation-duration: 0.5s;
    transition-timing-function: ease-in;
    opacity: 0; }
    @media (min-width: 1024px) {
      .m-scene .scene_element {
        padding: 0 25px; } }
  .m-scene .scene_element--fadeinup {
    animation-name: fadeInUp;
    opacity: 1; }
  .m-scene .scene_element--fadeinright {
    animation-name: fadeInRight; }
  .m-scene.is-exiting .scene_element {
    animation-direction: alternate-reverse; }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 60px, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(60px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

/** end js smooth **/
.pulse {
  animation: pulse 1s infinite alternate; }

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.9); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.tada {
  animation: tada 1s infinite alternate; }

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    transform: scale3d(1, 1, 1); } }

.jello {
  animation: jello 1s infinite alternate; }

@keyframes jello {
  from, 11.1%, to {
    transform: none; }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

/****************************************
** Header
*****************************************/
@media (min-width: 1024px) {
  .header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1; } }

.header.animate-in {
  opacity: 1; }

.header__left {
  display: inline-block; }

.header__logo_avatar {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  z-index: 10;
  position: relative; }

.header__logo_image {
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  margin-right: 25px; }

.header__title {
  display: inline-block; }

.header ul li,
.header .search input {
  display: block;
  padding: 5px 0;
  border: 0;
  width: 100%; }
  .header ul li:first-child,
  .header .search input:first-child {
    padding: 10px 0 5px; }

.header__description {
  font-size: 18px;
  font-family: inherit; }

.header .header__nav a {
  font-size: 18px; }

.header .navigation {
  position: absolute;
  display: block;
  z-index: 1; }
  .header .navigation li a {
    font-family: inherit;
    font-weight: 400; }

.header .search input[type="text"]:focus {
  outline: none;
  border-color: inherit; }

.header .search input[type="submit"] {
  display: none; }

/****************************************
** Content
*****************************************/
.content:after {
  content: "";
  display: table;
  clear: both; }

.content .posts:after {
  content: '';
  display: block;
  clear: both; }

.content .posts__container {
  padding: 15px 0; }
  @media (min-width: 768px) {
    .content .posts__container {
      padding: 15px; } }
  @media (min-width: 1024px) {
    .content .posts__container {
      padding: 40px; } }

.page-index .caption {
  max-width: 700px;
  display: inline-block; }

.post__footer {
  font-size: 16px; }
  .post__footer a,
  .post__footer .like_text {
    font-size: 16px; }
  .post__footer li {
    display: inline-block;
    margin-right: 10px; }
    .post__footer li:last-child {
      margin-right: 0; }
  .post__footer .custom-like-button {
    position: relative; }
  .post__footer .like_button {
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10; }
  .post__footer .like_button:hover + .like_text {
    opacity: 1; }
  .post__footer .like_button.liked + .like_text, .post__footer .like_text--liked {
    display: none; }
  .post__footer .like_button + .like_text + .like_text--liked {
    display: none;
    opacity: 0; }
  .post__footer .like_button.liked + .like_text + .like_text--liked {
    display: block;
    opacity: 1; }
  .post__footer .like_button:hover + .underline:after, .post__footer a:hover:after {
    transform: scaleX(0); }

/****************************************
** Text
*****************************************/
.post.text {
  padding: 20px; }
  @media (min-width: 768px) {
    .post.text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 80px); } }
  .post.text .post__text {
    max-width: 700px; }
  .post.text .pagination, .post.text .comments {
    padding: 20px 0 0; }

.page-permalink .post.text {
  padding: 40px 20px 20px; }

/****************************************
** Quote
*****************************************/
.post.quote {
  padding: 20px; }
  @media (min-width: 768px) {
    .post.quote {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 80px); } }
  .post.quote .post__quote {
    max-width: 700px; }
  .post.quote .source {
    font-weight: bold; }
  .post.quote .pagination, .post.quote .comments {
    padding: 20px 0 0; }

.page-permalink .post.quote {
  padding: 40px 20px 20px; }

/****************************************
** Link
*****************************************/
.post.link {
  padding: 20px; }
  @media (min-width: 768px) {
    .post.link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 80px); } }
  .post.link .post__link {
    max-width: 700px; }
  .post.link .pagination, .post.link .comments {
    padding: 20px 0 0; }

.page-permalink .post.link {
  padding: 40px 20px 20px; }

.photo .caption {
  text-align: left;
  padding: 20px; }
  @media (min-width: 768px) {
    .photo .caption {
      position: absolute;
      bottom: -15px;
      left: -15px; } }

.page-index .photo {
  position: relative; }
  @media (min-width: 768px) {
    .page-index .photo.cd-fixed-background {
      height: calc(100vh - 80px);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      background-attachment: fixed; } }
  @media (max-width: 767px) {
    .page-index .photo.cd-fixed-background {
      background: none !important; } }

.page-permalink .post.photo:not(.photoset) {
  display: inline-block; }

.page-permalink .post__photo {
  display: inline-block;
  position: relative; }
  .page-permalink .post__photo img {
    display: block; }
  .page-permalink .post__photo .caption {
    text-align: left; }

/****************************************
** Photosets
*****************************************/
.photoset .caption {
  text-align: left; }

.permalink .photoset .caption, .permalink .photoset .post__footer {
  text-align: left; }

.permalink .photoset .pagination, .permalink .photoset .notes {
  max-width: 900px;
  margin: 20px auto;
  width: 100%;
  display: block; }

.permalink .post__photoset {
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  position: relative; }

/****************************************
** Chat
*****************************************/
.post.chat {
  padding: 20px; }
  @media (min-width: 768px) {
    .post.chat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 80px); } }
  .post.chat .chat__list {
    list-style: none;
    max-width: 700px;
    margin: 0;
    padding: 0; }
    .post.chat .chat__list .chat__item {
      padding: 5px 0; }
      .post.chat .chat__list .chat__item:first-of-type {
        padding: 0 0 5px; }
  .post.chat .pagination, .post.chat .comments {
    padding: 20px 0 0; }

.page-permalink .post.chat {
  padding: 40px 20px 20px; }

.audio .post__audio {
  position: relative;
  max-width: 640px;
  width: 100%; }

.audio .caption {
  padding: 20px;
  line-height: 1.4444444444; }
  @media (min-width: 768px) {
    .audio .caption {
      position: absolute;
      bottom: -15px;
      left: -15px; } }

.page-index .audio {
  position: relative;
  line-height: 0; }
  @media (min-width: 768px) {
    .page-index .audio {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 80px); } }

iframe.spotify_audio_player {
  max-width: 100%; }

.audio .fluid-width-video-wrapper iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 640px;
  height: 100%;
  width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%); }

.video .post__video {
  max-width: 700px;
  width: 100%;
  position: relative; }

.page-index .video {
  position: relative; }
  @media (min-width: 768px) {
    .page-index .video {
      height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .page-index .video .post__video {
    max-width: 600px; }
  .page-index .video .caption {
    padding: 20px; }
    @media (min-width: 768px) {
      .page-index .video .caption {
        position: absolute;
        bottom: -15px;
        left: -15px; } }

.video .fluid-width-video-wrapper iframe:not([src^='https://www.tumblr.com']) {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 700px;
  height: 100%;
  width: 100%;
  max-height: 393.75px;
  transform: translate(-50%, -50%); }

.permalink .post.video {
  text-align: center;
  display: inline-block;
  width: 100%;
  max-width: 700px; }

/****************************************
** Answer
*****************************************/
.answer {
  border: 1px solid #dddddd;
  padding: 1em; }
  .answer .asker .asker-thumb {
    float: left;
    width: 40px;
    margin-bottom: 10px;
    margin-right: 10px; }
  .answer .asker .asker-name {
    position: relative;
    top: 6px; }
  .answer .question {
    clear: both;
    font-weight: bold; }

/****************************************
** Permalink Page
*****************************************/
.page-permalink {
  display: flex;
  justify-content: flex-start;
  flex-direction: column; }
  .page-permalink .content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.permalink {
  text-align: center; }
  @media (min-width: 768px) {
    .permalink {
      padding: 0 40px; } }
  .permalink article {
    padding: 40px 0px;
    margin: 0 auto;
    text-align: left; }
    @media (min-width: 768px) {
      .permalink article {
        padding: 40px 15px; } }
  .permalink .photo .caption,
  .permalink .video .caption {
    text-align: left;
    padding: 20px; }
    @media (min-width: 768px) {
      .permalink .photo .caption,
      .permalink .video .caption {
        position: absolute;
        bottom: -15px;
        left: -15px; } }
  .permalink .post.photo {
    text-align: center; }
  .permalink .post__video {
    display: inline-block; }
    .permalink .post__video .caption, .permalink .post__video .post__footer {
      text-align: left; }
  .permalink .post.audio {
    max-width: 640px;
    width: 100%;
    text-align: left; }
  .permalink .pagination, .permalink .comments {
    margin: 20px auto;
    text-align: left;
    padding: 20px 20px 0; }
  .permalink .tags {
    margin: 20px 0; }
    .permalink .tags li {
      display: inline-block;
      padding-right: 5px; }
  .permalink .like-and-reblog li, .permalink .pagination li {
    display: inline-block;
    padding-right: 5px; }
  .permalink .tags li a {
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 3px;
    font-family: 'Oswald', sans-serif;
    background: #81D0FF;
    color: white; }
    .permalink .tags li a:hover {
      background: #52A1D1;
      color: white; }
  .permalink .notes {
    text-align: left;
    padding: 20px; }
  .permalink .like-and-reblog {
    margin: 20px 0; }
  .permalink .pagination .next {
    float: right; }
  .permalink .avatar {
    display: none; }

[data-fluffy-content] {
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0; }
  [data-fluffy-content] img {
    display: block;
    float: left;
    height: 100%;
    margin: 0;
    padding: 0;
    width: auto; }
    @media (max-width: 767px) {
      [data-fluffy-content] img {
        max-height: 500px;
        height: initial; } }
  [data-fluffy-content]::after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    visibility: hidden; }

@keyframes moveRight {
  to {
    transform: translateX(20px); } }

@keyframes moveLeft {
  to {
    transform: translateX(-20px); } }

[data-fluffy-container] {
  background-color: rgba(255, 255, 255, 0.1);
  height: 50%;
  margin: auto;
  min-height: 320px;
  position: relative;
  transition: background-color 1s 600ms, opacity 600ms 600ms; }
  @media (min-width: 768px) {
    [data-fluffy-container] {
      height: calc(100vh - 80px); } }
  [data-fluffy-container]::after, [data-fluffy-container]::before {
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 50%;
    box-shadow: inset 0 0 20px 20px rgba(255, 255, 255, 0.33);
    content: "";
    height: 20px;
    left: 50%;
    margin: -10px 0 0 -10px;
    opacity: 1;
    position: absolute;
    top: 50%;
    visibility: visible;
    width: 20px;
    z-index: 1;
    transition: opacity 300ms, visibility 0 300ms; }
  [data-fluffy-container]::after {
    transform: translateX(-20px);
    animation: moveRight 600ms linear infinite alternate; }
  [data-fluffy-container]::before {
    transform: translateX(20px);
    animation: moveLeft 600ms linear infinite alternate; }
  [data-fluffy-container].is-ready {
    background-color: transparent; }
    [data-fluffy-container].is-ready::after, [data-fluffy-container].is-ready::before {
      opacity: 0;
      visibility: hidden;
      transition: opacity 300ms, visibility 0 300ms; }
    [data-fluffy-container].is-ready [data-fluffy-content] {
      opacity: 1; }
  [data-fluffy-container].is-moving [data-fluffy-scrollbars] {
    opacity: 1; }

[data-fluffy-scrollbars] {
  opacity: 0;
  transition: opacity 600ms; }
  [data-fluffy-scrollbars] .is-horizontal, [data-fluffy-scrollbars] .is-vertical {
    background-color: rgba(0, 0, 0, 0.66);
    box-shadow: inset 0 0 20px 20px rgba(255, 255, 255, 0.66);
    position: absolute;
    top: 0; }
  [data-fluffy-scrollbars] .is-horizontal {
    height: 3px;
    left: 0;
    width: 40px; }
  [data-fluffy-scrollbars] .is-vertical {
    height: 40px;
    right: 0;
    width: 3px; }

[data-fluffy-content] {
  opacity: 0;
  transition: opacity 600ms 600ms; }

.m0 {
  margin: 0; }

.mt0 {
  margin-top: 0; }

.mr0 {
  margin-right: 0; }

.mb0 {
  margin-bottom: 0; }

.ml0 {
  margin-left: 0; }

.mx0 {
  margin-left: 0;
  margin-right: 0; }

.my0 {
  margin-top: 0;
  margin-bottom: 0; }

.m0h {
  margin: 0.5rem; }

.mt0h {
  margin-top: 0.5rem; }

.mr0h {
  margin-right: 0.5rem; }

.mb0h {
  margin-bottom: 0.5rem; }

.ml0h {
  margin-left: 0.5rem; }

.mx0h {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.my0h {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.m1 {
  margin: 1rem; }

.mt1 {
  margin-top: 1rem; }

.mr1 {
  margin-right: 1rem; }

.mb1 {
  margin-bottom: 1rem; }

.ml1 {
  margin-left: 1rem; }

.mx1 {
  margin-left: 1rem;
  margin-right: 1rem; }

.my1 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.m2 {
  margin: 2rem; }

.mt2 {
  margin-top: 2rem; }

.mr2 {
  margin-right: 2rem; }

.mb2 {
  margin-bottom: 2rem; }

.ml2 {
  margin-left: 2rem; }

.mx2 {
  margin-left: 2rem;
  margin-right: 2rem; }

.my2 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mt2h {
  margin-top: 2.5rem; }

.m3 {
  margin: 3rem; }

.mt3 {
  margin-top: 3rem; }

.mr3 {
  margin-right: 3rem; }

.mb3 {
  margin-bottom: 3rem; }

.ml3 {
  margin-left: 3rem; }

.mx3 {
  margin-left: 3rem;
  margin-right: 3rem; }

.my3 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.m4 {
  margin: 4rem; }

.mt4 {
  margin-top: 4rem; }

.mr4 {
  margin-right: 4rem; }

.mb4 {
  margin-bottom: 4rem; }

.ml4 {
  margin-left: 4rem; }

.mx4 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my4 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.m5 {
  margin: 5rem; }

.mt5 {
  margin-top: 5rem; }

.mr5 {
  margin-right: 5rem; }

.mb5 {
  margin-bottom: 5rem; }

.ml5 {
  margin-left: 5rem; }

.mx5 {
  margin-left: 5rem;
  margin-right: 5rem; }

.my5 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.mxn1 {
  margin-left: -1rem;
  margin-right: -1rem; }

.mxn2 {
  margin-left: -2rem;
  margin-right: -2rem; }

.mxn3 {
  margin-left: -3rem;
  margin-right: -3rem; }

.mxn4 {
  margin-left: -4rem;
  margin-right: -4rem; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mx-auto {
  margin-left: auto;
  margin-right: auto; }

.mb7h {
  margin-bottom: 75px; }

.mb10 {
  margin-bottom: 100px; }

.ml3n {
  margin-left: -3rem; }

/* Basscss Padding */
.p0 {
  padding: 0; }

.pt0 {
  padding-top: 0; }

.pr0 {
  padding-right: 0; }

.pb0 {
  padding-bottom: 0; }

.pl0 {
  padding-left: 0; }

.px0 {
  padding-left: 0;
  padding-right: 0; }

.py0 {
  padding-top: 0;
  padding-bottom: 0; }

.p1 {
  padding: 1rem; }

.pt1 {
  padding-top: 1rem; }

.pr1 {
  padding-right: 1rem; }

.pb1 {
  padding-bottom: 1rem; }

.pl1 {
  padding-left: 1rem; }

.py1 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.px1 {
  padding-left: 1rem;
  padding-right: 1rem; }

.p2 {
  padding: 2rem; }

.pt2 {
  padding-top: 2rem; }

.pr2 {
  padding-right: 2rem; }

.pb2 {
  padding-bottom: 2rem; }

.pl2 {
  padding-left: 2rem; }

.py2 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.px2 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px2h {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.p3 {
  padding: 3rem; }

.pt3 {
  padding-top: 3rem; }

.pr3 {
  padding-right: 3rem; }

.pb3 {
  padding-bottom: 3rem; }

.pl3 {
  padding-left: 3rem; }

.py3 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.px3 {
  padding-left: 3rem;
  padding-right: 3rem; }

.p4 {
  padding: 4rem; }

.pt4 {
  padding-top: 4rem; }

.pr4 {
  padding-right: 4rem; }

.pb4 {
  padding-bottom: 4rem; }

.pl4 {
  padding-left: 4rem; }

.py4 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.px4 {
  padding-left: 4rem;
  padding-right: 4rem; }

.fit {
  max-width: 100%; }

.table {
  display: table; }

.table-cell {
  display: table-cell;
  vertical-align: middle; }

.w-100 {
  width: 100%; }

.text-right {
  text-align: right; }

@media (min-width: 768px) {
  .medium--hide {
    display: none; } }

.medium--hide > a {
  display: block;
  line-height: 0; }
