.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }
.mx0 { margin-left:   0; margin-right:  0 }
.my0 { margin-top:    0; margin-bottom: 0 }

.m0h  { margin:        0.5rem }
.mt0h { margin-top:    0.5rem }
.mr0h { margin-right:  0.5rem }
.mb0h { margin-bottom: 0.5rem }
.ml0h { margin-left:   0.5rem }
.mx0h { margin-left:   0.5rem; margin-right:  0.5rem }
.my0h { margin-top:    0.5rem; margin-bottom: 0.5rem }

.m1  { margin:        1rem }
.mt1 { margin-top:    1rem }
.mr1 { margin-right:  1rem }
.mb1 { margin-bottom: 1rem }
.ml1 { margin-left:   1rem }
.mx1 { margin-left:   1rem; margin-right:  1rem }
.my1 { margin-top:    1rem; margin-bottom: 1rem }

.m2  { margin:        2rem }
.mt2 { margin-top:    2rem }
.mr2 { margin-right:  2rem }
.mb2 { margin-bottom: 2rem }
.ml2 { margin-left:   2rem }
.mx2 { margin-left:   2rem; margin-right:  2rem }
.my2 { margin-top:    2rem; margin-bottom: 2rem }

.mt2h { margin-top:    2.5rem }

.m3  { margin:        3rem }
.mt3 { margin-top:    3rem }
.mr3 { margin-right:  3rem }
.mb3 { margin-bottom: 3rem }
.ml3 { margin-left:   3rem }
.mx3 { margin-left:   3rem; margin-right:  3rem }
.my3 { margin-top:    3rem; margin-bottom: 3rem }

.m4  { margin:        4rem }
.mt4 { margin-top:    4rem }
.mr4 { margin-right:  4rem }
.mb4 { margin-bottom: 4rem }
.ml4 { margin-left:   4rem }
.mx4 { margin-left:   4rem; margin-right:  4rem }
.my4 { margin-top:    4rem; margin-bottom: 4rem }

.m5  { margin:        5rem }
.mt5 { margin-top:    5rem }
.mr5 { margin-right:  5rem }
.mb5 { margin-bottom: 5rem }
.ml5 { margin-left:   5rem }
.mx5 { margin-left:   5rem; margin-right:  5rem }
.my5 { margin-top:    5rem; margin-bottom: 5rem }

.mxn1 { margin-left: -1rem; margin-right: -1rem; }
.mxn2 { margin-left: -2rem; margin-right: -2rem; }
.mxn3 { margin-left: -3rem; margin-right: -3rem; }
.mxn4 { margin-left: -4rem; margin-right: -4rem; }

.ml-auto { margin-left: auto }
.mr-auto { margin-right: auto }
.mx-auto { margin-left: auto; margin-right: auto; }

.mb7h { margin-bottom: 75px; }

.mb10 { margin-bottom: 100px; }

.ml3n { margin-left: -3rem }

