/****************************************
** Quote
*****************************************/

.post.quote {
    padding: 20px;
    @media (min-width: $medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        height: calc(100vh - 80px);
    }      
    .post__quote {
    	max-width: 700px;
    }	
    .source {
        font-weight: bold;
    }

    .pagination, .comments {
        padding: 20px 0 0;
    }

}

.page-permalink .post.quote {
    padding: 40px 20px 20px
}