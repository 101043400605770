/****************************************
** Content
*****************************************/

.content {
	&:after {
		content: "";
		display: table;
		clear: both;
	}

	.posts {
	  	&:after {
		    content: '';
		    display: block;
		    clear: both;
	  	}
	}	

	.posts__container {
	    padding: 15px 0;
	    @media (min-width: $medium) {
	    	padding: 15px;
	    }
	    @media (min-width: $large) {
	        padding: 40px;
	    }
	}

	// .caption {
	// 	a {
	// 		opacity: 0.5;
	// 		transition: opacity 0.2s;
	// 		&:hover {
	// 			opacity: 1;
	// 		}
	// 	}
	// }
}

.page-index .caption {
	max-width: 700px;
	display: inline-block;
}

