/****************************************
** Basics
*****************************************/
$small: 450px;
$medium: 768px;
$large: 1024px;

* {
    box-sizing: border-box;
}

body {
  @include font-smoothing;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-size: 62.5%;
}
body {
    background: white;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.4444444444;
    /*  The meta tag we wrote earlier for Google Web Fonts allows you to dynamically add the font name anywhere in the document. Using the text variable above allows all of this to work dynamically. */
}

h1 {
    font-weight: bold;
}

ol, ul {
    list-style: none;
}
a {
    color: #81D0FF;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* Basic image/video/media handling */

video, object, embed {
    height: auto !important;
}

video, object, embed {
    max-width: 100%;
}

.notes {
    display: none;
}

.wrapper {
    padding: 0 10px;
}

.container {
    padding: 10px 0;
}

@media (min-width: $large) {
    .wrapper {
        padding: 0 25px;
    }

    .container {
        padding: 25px 0;
    }
}

.overlay.hide {
    opacity: 0;
    display: none;
}

.overlay {
    transition: opacity 0.2s;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: white;
    .overlay__container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

#-hide {
    display: none;
}

iframe[id^=like_iframe] {
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
}

.more_notes_link {
    float: left;
}

.bottom {
    float: right;
    padding: 15px;
    text-align: right;
    @media (min-width: $large) {
        position: fixed;
        bottom: 25px;
        padding: 0;
        right: 25px;
    }    
    
}