.video {
    .post__video {
        max-width: 700px;
        width: 100%;
        position: relative;
    }    
}

.page-index .video {
    position: relative;
    @media (min-width: $medium) {
        height: calc(100vh - 80px); 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
    }

    .post__video {
        max-width: 600px;
    }
    
    .caption {
        @media (min-width: $medium) {
            position: absolute;
            bottom: -15px;
            left: -15px;
        }
	    padding: 20px;		
    }
}

.video .fluid-width-video-wrapper iframe:not([src^='https://www.tumblr.com']) {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 700px;
    height: 100%;
    width: 100%;
    max-height: 393.75px;
    transform: translate(-50%, -50%);
}

.permalink .post.video {
    text-align: center;
    display: inline-block;
    width: 100%;
    max-width: 700px;
}