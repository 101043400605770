.post__footer {
    font-size: 16px;
    a,
    .like_text {
        font-size: 16px;
        // opacity: 0.5;
        // transition: opacity 0.2s ease;
        // &:hover {
        //     opacity: 1;
        // }
    }

    li {
        display: inline-block;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }

    .custom-like-button {
        position: relative;
    }

    //tumblr likebutton
    .like_button {
        position: absolute;
        top: 3px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
    }

    .like_button:hover + .like_text {
        opacity: 1;
    }

    .like_button.liked+.like_text, .like_text--liked {
        display: none;
    }
    
    .like_button+.like_text+.like_text--liked {
        display: none;
        opacity: 0;
    }

    .like_button.liked+.like_text+.like_text--liked {
        display: block;
        opacity: 1;
    }

    .like_button:hover+.underline:after, a:hover:after {
        transform: scaleX(0);
    }
}