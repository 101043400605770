.audio {
    .post__audio {
        position: relative;
        max-width: 640px;
        width: 100%;
    }      
    .caption {
        padding: 20px;
        line-height: 1.4444444444;
        @media (min-width: $medium) {
            position: absolute;
            bottom: -15px;
            left: -15px;
        }               
    }    
}

.page-index .audio {
    position: relative;
    line-height: 0;
    @media (min-width: $medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        height: calc(100vh - 80px);
    } 
}

iframe.spotify_audio_player {
    max-width: 100%;
}

.audio .fluid-width-video-wrapper iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 640px;
    height: 100%;
    width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
}
