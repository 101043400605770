/* Basscss Padding */

.p0  { padding: 0 }
.pt0 { padding-top: 0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom: 0 }
.pl0 { padding-left: 0 }
.px0 { padding-left: 0; padding-right:  0 }
.py0 { padding-top: 0;  padding-bottom: 0 }

.p1  { padding:        1rem }
.pt1 { padding-top:    1rem }
.pr1 { padding-right:  1rem }
.pb1 { padding-bottom: 1rem }
.pl1 { padding-left:   1rem }
.py1 { padding-top:    1rem; padding-bottom: 1rem }
.px1 { padding-left:   1rem; padding-right:  1rem }

.p2  { padding:        2rem }
.pt2 { padding-top:    2rem }
.pr2 { padding-right:  2rem }
.pb2 { padding-bottom: 2rem }
.pl2 { padding-left:   2rem }
.py2 { padding-top:    2rem; padding-bottom: 2rem }
.px2 { padding-left:   2rem; padding-right:  2rem }

.px2h { padding-left: 2.5rem; padding-right:  2.5rem }

.p3  { padding:        3rem }
.pt3 { padding-top:    3rem }
.pr3 { padding-right:  3rem }
.pb3 { padding-bottom: 3rem }
.pl3 { padding-left:   3rem }
.py3 { padding-top:    3rem; padding-bottom: 3rem }
.px3 { padding-left:   3rem; padding-right:  3rem }

.p4  { padding:        4rem }
.pt4 { padding-top:    4rem }
.pr4 { padding-right:  4rem }
.pb4 { padding-bottom: 4rem }
.pl4 { padding-left:   4rem }
.py4 { padding-top:    4rem; padding-bottom: 4rem }
.px4 { padding-left:   4rem; padding-right:  4rem }



