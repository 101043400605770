.m-scene {
    overflow-x: hidden;
    .scene_element {
        animation-duration: 0.5s;
        transition-timing-function: ease-in;
        opacity: 0;
        @media (min-width: $large) {
        	padding: 0 25px;
        }
    }
    .scene_element--fadeinup {
        animation-name: fadeInUp;
        opacity: 1;
    }
    .scene_element--fadeinright {
        animation-name: fadeInRight;
    }
    &.is-exiting .scene_element {
        animation-direction: alternate-reverse;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 60px, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(60px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/** end js smooth **/

.pulse {
    animation: pulse 1s infinite alternate;
}

@keyframes pulse {
    0% {
        opacity: 0;
        transform: scale(.9)
    }
    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.tada {
    animation: tada 1s infinite alternate;
}

@keyframes tada {
    from {
        transform: scale3d(1, 1, 1);
    }

    10%, 20% {
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%, 50%, 70%, 90% { 
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%, 60%, 80% {
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}

.jello {
    animation: jello 1s infinite alternate;
}

@keyframes jello {
    from, 11.1%, to {
        transform: none;
    }

    22.2% {
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }

    33.3% {
        transform: skewX(6.25deg) skewY(6.25deg);
    }

    44.4% {
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }

    55.5% {
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }

    66.6% {
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }

    77.7% {
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }

    88.8% {
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}
