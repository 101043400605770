.fit {
	max-width: 100%;
}

.table {
	display: table;
}

.table-cell {
	display: table-cell;
	vertical-align: middle;
}

.w-100 {
	width: 100%;
}

.text-right {
	text-align: right;
}

@media (min-width: $medium) {
	.medium--hide {
		display: none;
	}	
}

.medium--hide > a {
	display: block;
    line-height: 0;
}