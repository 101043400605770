/****************************************
** Typography
*****************************************/

h1, .h1 {
    font-size: 24px;
}
h2, .h2 {
    font-size: 28px;
}
h3, .h3 {
    font-size: 24px;
}
h4, .h4 {
    font-size: 18px;
}
h5, .h5 {
    font-size: 14px;
}
h6, .h6 {
    font-size: 12px;
}
h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
}

input {
	font-size: 16px;
}

