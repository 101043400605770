/****************************************
** Permalink Page
*****************************************/
.page-permalink {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.permalink {
    text-align: center;
    @media (min-width: $medium) {
        padding: 0 40px;
    }
    article {
        padding: 40px 0px;
        margin: 0 auto;
        text-align: left;
        @media (min-width: $medium) {
            padding: 40px 15px;
        }
    }

    .photo .caption,
    .video .caption {
        text-align: left;
        @media (min-width: $medium) {
            position: absolute;
            bottom: -15px;
            left: -15px;            
        }
        padding: 20px;      
    }   

    .post.photo {
        text-align: center;
    }

    .post__video {
        display: inline-block;
        .caption, .post__footer {
            text-align: left;
        }        
    }

    .post.audio {
        max-width: 640px;
        width: 100%;
        text-align: left;
    }

    .pagination, .comments {
        margin: 20px auto;
        text-align: left;
        padding: 20px 20px 0;
    }
    .tags {
        margin: 20px 0;
         li {
            display: inline-block;
            padding-right: 5px;
        }
    }
    .like-and-reblog li, .pagination li {
        display: inline-block;
        padding-right: 5px;
    }
    .tags li a {
        padding: 5px 10px;
        font-size: 13px;
        border-radius: 3px;
        font-family: 'Oswald', sans-serif;
        background: #81D0FF;
        color: white;
        &:hover {
            background: #52A1D1;
            color: white;
        }
    }

    .notes {
        text-align: left;
        padding: 20px;
    }

    .like-and-reblog {
        margin: 20px 0;
    }
    .pagination .next {
        float: right;
    }
    .avatar {
        display: none;
    }

}