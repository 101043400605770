@mixin font-smoothing {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@mixin font-face($font-family, $file-path) {
  @font-face {
    font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
      url('#{$file-path}.woff2') format('woff2'), url('#{$file-path}.woff') format('woff'), url('#{$file-path}.ttf') format('truetype')
  }
  // Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @font-face {
      font-family: $font-family;
        src: url('#{$file-path}.svg##{$font-family}') format('svg');
    }
  }
}

