/****************************************
** Chat
*****************************************/

.post.chat {
    padding: 20px;  
    @media (min-width: $medium) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        height: calc(100vh - 80px);
    }  
    .chat__list {
        list-style: none;
        max-width: 700px;
        margin: 0;
        padding: 0;
        .chat__item {
            padding: 5px 0;
            &:first-of-type {
                padding: 0 0 5px;
            }
        }        
    }
    .pagination, .comments {
        padding: 20px 0 0;
    }
}

.page-permalink .post.chat {
    padding: 40px 20px 20px
}