/****************************************
** Answer
*****************************************/

.answer {
    border: 1px solid #dddddd;
    padding: 1em;
    .asker {
        .asker-thumb {
            float: left;
            width: 40px;
            margin-bottom: 10px;
            margin-right: 10px;
        }
        .asker-name {
            position: relative;
            top: 6px;
        }
    }
    .question {
        clear: both;
        font-weight: bold;
    }
    .question-answer {}
}