/****************************************
** Photosets
*****************************************/
.photoset {    
    .caption {
    	text-align: left;
    }
}

.permalink {
    .photoset {
        .caption, .post__footer {
            text-align: left;
        }   
        .pagination, .notes {
            max-width: 900px;
            margin: 20px auto;
            width: 100%;
            display: block;        
        }        
    }
    .post__photoset {
        max-width: 900px;
        margin: 0 auto;
        width: 100%;
        position: relative;        
    }
}