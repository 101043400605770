[data-fluffy-content] {
  img {
    display: block;
    float: left;
    height: 100%;
    margin: 0;
    padding: 0;
    width: auto;
    @media (max-width: $medium - 1) {
        max-height: 500px;
        height: initial;
    }
  }
  height: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  &::after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    visibility: hidden;
  }
}

@keyframes moveRight {
  to {
    transform: translateX(20px);
  }
}


@keyframes moveLeft {
  to {
    transform: translateX(-20px);
  }
}


[data-fluffy-container] {
  background-color: rgba(255, 255, 255, 0.1);
  height: 50%;
  margin: auto;
  min-height: 320px;
  position: relative;
  transition: background-color 1s 600ms, opacity 600ms 600ms;
  @media (min-width: $medium) {
    height: calc(100vh - 80px);
  }
  &::after, &::before {
    background-color: rgba(0, 0, 0, 0.33);
    border-radius: 50%;
    box-shadow: inset 0 0 20px 20px rgba(255, 255, 255, 0.33);
    content: "";
    height: 20px;
    left: 50%;
    margin: -10px 0 0 -10px;
    opacity: 1;
    position: absolute;
    top: 50%;
    visibility: visible;
    width: 20px;
    z-index: 1;
    transition: opacity 300ms, visibility 0 300ms;
  }
  &::after {
    transform: translateX(-20px);
    animation: moveRight 600ms linear infinite alternate;
  }
  &::before {
    transform: translateX(20px);
    animation: moveLeft 600ms linear infinite alternate;
  }
  &.is-ready {
    background-color: transparent;
    &::after, &::before {
      opacity: 0;
      visibility: hidden;
      transition: opacity 300ms, visibility 0 300ms;
    }
    [data-fluffy-content] {
      opacity: 1;
    }
  }
  &.is-moving [data-fluffy-scrollbars] {
    opacity: 1;
  }
}

[data-fluffy-scrollbars] {
  opacity: 0;
  transition: opacity 600ms;
  .is-horizontal, .is-vertical {
    background-color: rgba(0, 0, 0, 0.66);
    box-shadow: inset 0 0 20px 20px rgba(255, 255, 255, 0.66);
    position: absolute;
    top: 0;
  }
  .is-horizontal {
    height: 3px;
    left: 0;
    width: 40px;
  }
  .is-vertical {
    height: 40px;
    right: 0;
    width: 3px;
  }
}

[data-fluffy-content] {
  opacity: 0;
  transition: opacity 600ms 600ms;
}